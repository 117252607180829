import { isUndefined } from '@/helpers/typeGuards';
import { supportedLocalesUI } from '@/plugins/i18n/supportedLocales';
import {
  IContextUserExternal,
  ContextTypeEnum,
  IContextBase,
  IContextAuthenticationAccessToken,
  IContextComponentWithUnit,
  Environment,
} from '@prestashopcorp/billing-cdc';
import { ContextOfferSelection } from './ContextOfferSelection';
import { ContextOrganization } from './ContextOrganization';
import { ContextProduct } from './ContextProduct';
import { ContextRoot, IContextBaseUser, contextRequiredFields } from './ContextRoot';
import { LangIsoCode } from '@/models/enums/LangIsoCode';
import { CustomerType } from '@/models/enums/CustomerType';

export const contextUserAppRequiredFields = [
  ...contextRequiredFields,
  'organization.uuid',
  'organization.email',
];

export class ContextUser
  extends ContextRoot
  implements IContextBase<IContextAuthenticationAccessToken>, IContextBaseUser
{
  contextType: ContextTypeEnum.USER;
  contextVersion: 2;
  isSandbox: boolean;
  environment: Environment;
  i18n: { isoCode: LangIsoCode };
  authentication: IContextAuthenticationAccessToken;
  organization: ContextOrganization;
  product?: ContextProduct;
  offerSelection?: ContextOfferSelection;

  /**
   * Constructor to create a ContextUser.
   *
   * @param xpropsContext
   */
  constructor(xpropsContext: IContextUserExternal) {
    super(contextUserAppRequiredFields, xpropsContext);
    this.validatePayloadContext<IContextUserExternal>(xpropsContext);

    this.contextVersion = 2;
    this.authentication = { accessToken: xpropsContext.accessToken };
    this.contextType = ContextTypeEnum.USER;
    this.isSandbox = xpropsContext.isSandbox;
    this.environment = xpropsContext.billingEnv;

    // Convert user locale to supported locale in UI
    this.i18n = {
      ...xpropsContext.i18n,
      isoCode: supportedLocalesUI(xpropsContext.i18n.isoCode),
    };

    this.organization = new ContextOrganization(
      xpropsContext.organization.uuid,
      xpropsContext.organization.email,
    );

    if (!isUndefined(xpropsContext.product)) {
      this.product = new ContextProduct({ ...xpropsContext.product });
    }

    if (!isUndefined(xpropsContext.offerSelection)) {
      this.offerSelection = new ContextOfferSelection(
        xpropsContext.offerSelection.offerPricingId,
        xpropsContext.offerSelection.offerQuantity,
        xpropsContext.offerSelection.optionalComponents,
        xpropsContext.offerSelection.singleSubscriptionIdentifier,
      );
    }
  }

  get customerId(): string {
    return this.organization.uuid;
  }

  get customerType(): CustomerType {
    return CustomerType.USER;
  }

  /**
   * Return the product component with the id given in param
   *
   * @param componentId component id
   * @returns
   */
  getProductComponentById(componentId: string): IContextComponentWithUnit | undefined {
    if (isUndefined(this.product)) {
      return;
    }
    return this.product.getComponentById(componentId);
  }
}

export const isContextUser = (value: ContextUser | unknown): value is ContextUser => {
  return (
    value instanceof ContextUser || (value as ContextUser).contextType === ContextTypeEnum.USER
  );
};
